module.exports = {
	siteTitle: "Sancho's Portfolio", // <title>
	manifestName: 'Portfolio',
	manifestShortName: 'Landing', // max 12 characters
	manifestStartUrl: '/',
	manifestBackgroundColor: '#663399',
	manifestThemeColor: '#663399',
	manifestDisplay: 'standalone',
	manifestIcon: 'src/assets/images/favicon.png',
	pathPrefix: `/portfolio-page/`, // This path is subpath of your hosting https://domain/portfolio
	authorName: 'Sancho Saiyin',
	heading: 'Web Developer',
	// social
	socialLinks: [
		{
			icon: 'fa-home',
			name: 'Home Page',
			url: 'https://realsancho.me',
		},
		{
			icon: 'fa-github',
			name: 'Github',
			url: 'https://github.com/saincogt',
		},
		{
			icon: 'fa-gitlab',
			name: 'GitLab',
			url: 'https://gitlab.com/saincogt',
		},
		{
			icon: 'fa-envelope-o',
			name: 'Email',
			url: 'mailto:saincogt@gmail.com',
		},
	],
};
